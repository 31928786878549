import { identity } from 'lodash';

export interface ListDiff<T> {
  added: T[];
  removed: T[];
}

/**
 * get the difference between two lists
 * @param oldList original list
 * @param newList updated list
 * @param idFunction generate a unique id for each item
 */
export function listDiff<T>(
  oldList: readonly T[],
  newList: readonly T[],
  idFunction: (item: T) => unknown = identity,
): ListDiff<T> {
  const oldSet = new Set(oldList.map(idFunction));
  const newSet = new Set(newList.map(idFunction));

  const added = newList.filter(item => !oldSet.has(idFunction(item)));
  const removed = oldList.filter(item => !newSet.has(idFunction(item)));

  return { added, removed };
}
