
import uniqBy from 'lodash/uniqBy';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isSelectOptions, SelectOption } from '@/components/form/form.types';
import { listDiff } from '@/utils/list';
import last from 'lodash/last';
import invariant from 'ts-invariant';
interface SelectCustomEvent extends CustomEvent {
  detail: {
    selectedIndex: number[];
    value: string[]; // this string is useless
  };
}
@Component({})
export default class GMultiSelect extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: string[];
  @Prop(String) readonly label!: string;
  @Prop(String) readonly placeholder!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly single!: boolean;
  @Prop(Array) readonly options!: SelectOption[] | string[];
  // keyed by options because the webcomponent didn't handle updating options after initial render
  get key(): string {
    return JSON.stringify(this.options);
  }
  get selected(): number[] {
    return this.value.map(v => this.formattedOptions.findIndex(opt => opt.value === v));
  }
  get formattedOptions(): SelectOption[] {
    if (!isSelectOptions(this.options)) {
      return this.options.map(option => {
        return {
          key: option,
          value: option,
          label: option,
          disabled: false,
        };
      });
    }
    //to avoid duplicates in multiselect list item keys
    let newOptions = uniqBy(this.options, option => option.value);
    return newOptions.map(option => {
      const out = { ...option };
      if (!out.key) {
        out.key = out.value;
      }
      if (!out.label) {
        out.label = out.value;
      }
      return out;
    });
  }
  get eventHandlers(): Record<string, unknown> {
    return {
      change: this.onChange,
      // added for validation framework
      blur: (e: Event) => this.$emit('blur', e),
    };
  }
  prevValue: string[] = [];
  onChange(e: SelectCustomEvent): void {
    // unwanted change event is emitted sometimes
    if (!e.detail.selectedIndex) {
      return;
    }
    let v = e.detail.selectedIndex.map(i => this.formattedOptions[i].value);
    if (this.single) {
      const diff = listDiff(this.prevValue, v);
      if (diff.added.length) {
        const l = last(diff.added);
        invariant(l, 'there must be a last item');
        v = [l];
      } else if (diff.removed.length) {
        const left = this.prevValue.filter(v => !diff.removed.includes(v));
        if (left.length) {
          const l = last(left);
          invariant(l, 'there must be a last item');
          v = [l];
        } else {
          v = [];
        }
      } else if (this.prevValue.length >= 1) {
        const l = last(this.prevValue);
        invariant(l, 'there must be a last item');
        v = [l];
      } else {
        v = [];
      }
    }

    this.$emit('input', v);
    // added for validation framework
    this.$emit('change', v);
    this.prevValue = v;
  }
  optionText(option: SelectOption): string {
    if (typeof option.label === 'string') {
      return option.label;
    } else {
      return option.value;
    }
  }
  optionChecked(option: SelectOption): boolean {
    return !!this.value.find(v => v === option.value);
  }
}
